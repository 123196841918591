import Vue from 'vue';
import VueI18n from 'vue-i18n';

import zh from '@/lang/i18n/zh.json';
import en from '@/lang/i18n/en.json';
import ja from '@/lang/i18n/ja.json';
import idID from '@/lang/i18n/id-ID.json';
import thTH from '@/lang/i18n/th-TH.json';
import filPH from '@/lang/i18n/fil-PH.json';
import viVN from '@/lang/i18n/vi-VN.json';

Vue.use(VueI18n);

const locale = 'zh';

const messages = {
    zh: zh,
    en: en,
    ja: ja,
    id: idID,
    th: thTH,
    fil: filPH,
    vi: viVN
};

const i18n = new VueI18n({
    locale,
    messages
});

export default i18n;
