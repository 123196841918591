import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/lang/lang.js';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import('@/views/UserCms.vue'),
        redirect: {
            name: 'Dashboard'
        },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('@/components/Dashboard.vue'),
                meta: { title: '__dashboard' }
            },
            {
                path: 'contact',
                name: 'Contact',
                component: () => import('@/components/Contact.vue'),
                meta: { title: '__contactUs' }
            },
            {
                path: 'notify-template/:id',
                name: 'NotifyTemplate',
                component: () => import('@/components/NotifyTemplate.vue'),
                meta: { title: '__notifyTemplateSettings' }
            }
        ]
    },
    {
        path: '/auth',
        component: () => import('@/views/Auth.vue'),
        redirect: {
            name: 'Login'
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/components/auth/Login.vue'),
                meta: { title: '__login' }
            },
            {
                path: 'change-password',
                name: 'Change Password',
                component: () => import('@/components/auth/ChangePassword.vue'),
                meta: { title: '__changeYourPassword' }
            },
            {
                path: 'token/:token',
                name: 'LoginWithToken',
                component: () => import('@/components/auth/LoginWithToken.vue'),
                meta: { title: '__login' }
            }
        ]
    },
    {
        path: '/report',
        component: () => import('@/views/UserCms.vue'),
        name: 'Report',
        redirect: {
            name: 'ReportDailyRiskSummary'
        },
        children: [
            {
                path: 'search-resident',
                name: 'SearchReportResident',
                component: () => import('@/components/SearchReportResident.vue'),
                meta: { title: '__reportResident' }
            },
            {
                path: 'notify-report',
                name: 'ReportNotifyRecord',
                component: () => import('@/components/ReportNotifyRecord.vue'),
                meta: { title: '__notifyReport' }
            },
            {
                path: 'resident',
                name: 'ReportResident',
                component: () => import('@/components/ReportResident.vue'),
                meta: { title: '__reportResident' },
                redirect: {
                    name: 'SearchReportResident'
                },
                children: [
                    {
                        path: 'graphical',
                        name: 'Graphical',
                        component: () => import('@/components/ReportResidentGraphical.vue'),
                        meta: { title: '__reportResident' }
                    },
                    {
                        path: 'notify',
                        name: 'Notify',
                        component: () => import('@/components/ReportResidentNotify.vue'),
                        meta: { title: '__reportResident' }
                    }
                ]
            },
            {
                path: 'statistics',
                name: 'Statistics',
                component: () => import('@/components/ReportStatistics.vue'),
                meta: { title: '__reportStatistics' }
            },
            {
                path: 'daily-risk-summary-report',
                name: 'ReportDailyRiskSummary',
                component: () => import('@/components/ReportDailyRiskSummary.vue'),
                meta: { title: '__reportDailyRiskSummary' }
            },
            {
                path: 'weekly-risk-summary-report',
                name: 'ReportWeeklyRiskSummary',
                component: () => import('@/components/ReportWeeklyRiskSummary.vue'),
                meta: { title: '__reportWeeklyRiskSummary' }
            }
        ]
    },
    {
        path: '/device-management',
        component: () => import('@/views/UserCms.vue'),
        name: 'DeviceManagement',
        redirect: {
            name: 'DeviceResident'
        },
        children: [
            {
                path: 'device',
                name: 'DeviceResident',
                component: () => import('@/components/DeviceResident.vue'),
                meta: { title: '__residentDevice' }
            },
            {
                path: 'resident-management',
                name: 'ResidentManagement',
                component: () => import('@/components/ResidentManagement.vue'),
                meta: { title: '__residentManagement' }
            },
            {
                path: 'network-management',
                name: 'NetworkManagement',
                component: () => import('@/components/NetworkManagement.vue'),
                meta: { title: '__networkManagement' }
            }
        ]
    },
    {
        path: '/other-device',
        component: () => import('@/views/UserCms.vue'),
        name: 'OtherDevice',
        redirect: {
            name: 'ForaMeasurement'
        },
        children: [
            {
                path: 'fora',
                name: 'ForaMeasurement',
                component: () => import('@/components/ForaMeasurement.vue'),
                meta: { title: '__residentDevice' }
            },
            {
                path: 'chiline',
                name: 'ChilineMeasurement',
                component: () => import('@/components/ChilineMeasurement.vue'),
                meta: { title: '__residentManagement' }
            },
            {
                path: 'ecg-report',
                name: 'ReportEcgRecord',
                component: () => import('@/components/ReportEcgRecord.vue'),
                meta: { title: '__ecgReport' }
            }
        ]
    },
    {
        path: '/line-notify-callback',
        name: 'LineNotifyCallBack',
        component: () => import('@/views/LineNotifyCallBack.vue')
    },
    {
        path: '*',
        name: 'Error',
        component: () => import('@/views/ErrorPage.vue'),
        meta: { title: '404 Error' }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active'
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('humetrics_user_token');
    window.document.title = i18n.t(to.meta.title) + ' | ' + i18n.t('__humetrics');
    document.documentElement.lang = i18n.locale;

    if (!isAuthenticated && to.name !== 'Login' && to.name !== 'LoginWithToken') {
        next({ name: 'Login' });
    } else if (to.name === 'Login' && isAuthenticated) {
        next({ name: 'Dashboard' });
    } else {
        next();
    }
});
export default router;
